<template>
  <div class="header ">

    <div class="con">

      <div class="ul">

        <div class="li">

          <a href="index.html">

            <span class="logo"
              style="background: url(https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2021/eleimg/images/wap/waplogo.png);"></span>

          </a>

        </div>

      </div>

      <div class="con_left">



        <div class="ul">

          <div class="li dr">

            <div class="tit">

              <span>车型探索</span>

            </div>

            <div class="drops">

              <!-- Tab panes -->

              <div class="drops_left">

                <div class="tab-content">
                  <div role="tabpanel" class="tab-pane active" id="nav6">

                    <img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2022/images/pcs7.gif">

                  </div>
                  <div role="tabpanel" class="tab-pane " id="nav1">

                    <img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2022/images/F9.gif">

                  </div>

                  <div role="tabpanel" class="tab-pane" id="nav2">

                    <img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2022/images/nav1.png">

                  </div>

                  <div role="tabpanel" class="tab-pane" id="nav3">

                    <img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2022/images/i-OneXS.gif">

                  </div>

                  <div role="tabpanel" class="tab-pane" id="nav4">

                    <img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2022/images/i-OneX.gif">

                  </div>

                  <div role="tabpanel" class="tab-pane" id="nav5">

                    <img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2022/images/i-like.gif">

                  </div>

                </div>

              </div>





              <div class="drops_right">

                <ul class="nav nav-tabs" role="tablist">
                  <li role="presentation" class="active"><a href="#nav6" aria-controls="nav6" role="tab"
                      data-toggle="tab"><a href="./s7.html">S7 <span class="logo-below">KYMCO<span></span></span></a></a></li>

                  <li role="presentation"><a href="#nav1" aria-controls="nav1" role="tab" data-toggle="tab"><a
                        href="./f9.html">F9 <span class="logo-below">KYMCO<span></span></span></a></a></li>


                  <li role="presentation"><a href="#nav3" aria-controls="nav3" role="tab" data-toggle="tab"><a
                        href="./i_One_XS.html">i-One XS <span class="logo-below">KYMCO<span></span></span></a></a></li>

                  <li role="presentation"><a href="#nav4" aria-controls="nav4" role="tab" data-toggle="tab"><a
                        href="./i_One_X.html">i-One X <span class="logo-below">KYMCO<span></span></span></a></a></li>

                  <li role="presentation"><a href="#nav5" aria-controls="nav5" role="tab" data-toggle="tab"><a
                        href="./i_LIKE.html">i-Like <span class="logo-below">KYMCO<span></span></span></a></a></li>


                </ul>

              </div>

            </div>

          </div>



          <div class="li">

            <div class="tit">

              <span><a href="ionex.html">Ionex车能网</a></span>

            </div>



          </div>

          <div class="li">

            <div class="tit">

              <span><router-link :to="{name:'new'}"> 发现光阳 </router-link></span>

            </div>



          </div>

          <div class="li">

            <div class="tit">

              <span><a href="contact.html">联系我们</a></span>

            </div>



          </div>

          <div class="li">

            <div class="tit">

              <span><a href="new.html">动态</a></span>

            </div>



          </div>

          <div class="li">

            <div class="tit">

              <span><a href="">APP</a></span>

            </div>



          </div>

        </div>

      </div>

      <div class="items-center con_right">

        
          <img
  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16588214747231884085.png"
  class="image_1"
/>

          <span style="margin-left: 12px;">商城</span>

        

      </div>

    </div>



  </div>

</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  async mounted(){
    await this.$nextTick(()=>{
      // 下拉按钮
$('.header .ul .li.dr').mouseenter(function() {
	// 初始化
	
	$('.header .ul .li .drops').css('display', 'none');
	$(this).children('.drops').css('display', 'block');
	$(this).addClass('active');
	$('#mantle').css('display', 'block');
});
$('.header .ul .li.dr').mouseleave(function() {
	$('.header .ul .li .drops').css('display', 'none');
	$(this).removeClass('active');
	$('#mantle').css('display', 'none');
});
$('.header .ul .li').mouseenter(function() {
	$(this).addClass('active');
});
$('.header .ul .li').mouseleave(function() {
	$(this).removeClass('active');
});
 $('.header').addClass('scroll_top');

		// 检测滚动高度

		var p=0,t=0;

		$(document).scroll(function() {

			var scroH = $(document).scrollTop(); //滚动高度

			p=$(this).scrollTop();

		

			if(t<p){

				if (scroH > 100) { //距离顶部大于100px时

					$('.header').addClass('scroll_top');

					$('.header').css('display', 'none');

				}else{

					if(scroH<=0){
										  
					}else{
										  $('.header').removeClass('scroll_top');
										  
										  $('.header').css('display', 'none');
					}

				}

		

			}else{

			   $('.header').css('display', 'block');

			   if (scroH > 100) { //距离顶部大于100px时

			   	$('.header').addClass('scroll_top');

			   }else{

			   	$('.header').addClass('scroll_top');

			   }

		

			 }

		

			setTimeout(function(){ t = p ; },0)

		

			

		});

    });
  },
  methods: {},
};
</script>

<style scoped lang="css">
.image_1 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
}
.logo-below {
  display: inline-block;
  font-size: 16px;
}

.logo-below2 {
  display: inline-block;
  font-size: 12px;
  font-weight: 200 !important;
}

/* header */

.header {
	position: fixed;

	top: 0;

	width: 100%;

	height: 80px;

	/* box-shadow: 2px 0 5px 2px rgba(0, 0, 0, 0.8); */

	z-index: 9996;

	/* background-color: #fff; */
}

.header.all_action {
	background-color: #fff;

	box-shadow: 2px 0 5px 2px rgb(0 0 0 / 10%);
}

.header.scroll_top {
	background-color: #fff;

	box-shadow: 2px 0 5px 2px rgb(0 0 0 / 10%);
}
.header.scroll_top .con .con_left .ul .li:hover a {
	color: #000;
}
.header.scroll_top .con .con_left .ul .li {
	color: #000;
}

.header.scroll_top .con .con_left .ul .li a {
	color: #000;
}
.header.scroll_top .con .con_right a {
	color: #000;
}
.header.scroll_top .con .con_right .menu_log {
	background-image: url(https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629d55ef74c9bd0011bdaf63/16582806713690270267.png) !important;
}
.header.scroll_top .con .con_left .ul .li.active .tit span:after {
	position: absolute;

	content: "";

	width: 6px;

	height: 6px;

	border-radius: 50%;

	top: 68px;

	left: 0px;

	background-color: #00a0ff;

	margin-left: 50%;
}
.header.scroll_top .con .ul .li span.logo {
	background-image: url(https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2021/eleimg/images/wap/waplogos.png) !important;
}


.header.addclass {
	background-color: #fff;

	box-shadow: 2px 0 5px 2px rgb(0 0 0 / 10%);
}
.header.addclass .con .con_left .ul .li:hover a {
	color: #000;
}
.header.addclass .con .con_left .ul .li {
	color: #000;
}

.header.addclass .con .con_left .ul .li a {
	color: #000;
}
.header.addclass .con .con_right a {
	color: #000;
}
.header.addclass .con .con_right .menu_log {
	background-image: url(https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629d55ef74c9bd0011bdaf63/16582806713690270267.png) !important;
}
.header.addclass .con .con_left .ul .li.active .tit span:after {
	position: absolute;

	content: "";

	width: 6px;

	height: 6px;

	border-radius: 50%;

	top: 68px;

	left: 0px;

	background-color: #00a0ff;

	margin-left: 50%;
}
.header.addclass .con .ul .li span.logo {
	background-image: url(https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2021/eleimg/images/wap/waplogos.png) !important;
}

.header .con {
	height: 80px;

	width: 94%;

	margin: 0 auto;

	display: flex;

	justify-content: space-between;

	position: relative;
}



.header .con .con_left .ul {
	width: auto;

	height: 100%;

	display: flex;

	flex-direction: row;

	align-items: center;
}

.header .con .con_left .ul .li {
	align-items: center;

	padding-left: 20px;

	padding-right: 20px;

	cursor: pointer;

	height: 80px;

	line-height: 80px;

	color: #e1e1e1;

	font-size: 18px;
}

/* 隐藏首页app */

.header .con .con_left .ul .li:last-child {
	visibility: hidden;
}

.header .con .con_left .ul .li a {
	color: #e1e1e1;

	text-decoration: none;
}

.header.all_action .con .con_left .ul .li.alls a {
	color: #fff;
}

.header .con .con_left .ul .li.alls:hover a {
	color: #fff;
}


.header .con .con_left .ul .li:hover a {
	color: #fff;
}

.header.all_action .con .con_left .ul .li {
	color: #000;
}



.header .con .con_left .ul .li.active {
	/* color: #fff; */
}

.header .con .con_left .ul .li.all a {
	color: #000;
}

.header .con .con_left .ul .li .tit {
	position: relative;
}

.header .con .con_left .ul .li .tit span {
	display: block;
}

.header .con .con_left .ul .li.active .tit span:after {
	position: absolute;

	content: "";

	width: 6px;

	height: 6px;

	border-radius: 50%;

	top: 68px;

	left: 0px;

	background-color: #00a0ff;

	margin-left: 50%;
}

.header .con .con_left .ul .li.all .tit span:after {
	position: absolute;

	content: "";

	width: 6px;

	height: 6px;

	border-radius: 50%;

	top: 68px;

	left: 0px;

	background-color: red;

	margin-left: 50%;
}



.header .con .ul .li .logo {
	width: 322px;

	height: 80px;

	display: block;
}

.header .con .ul .li span {
	display: inline-block;

	background-position: center !important;

	background-repeat: no-repeat !important;

	background-size: 100% !important;

	line-height: 80px;

	/* height: 80px; */
}

.header.all_action .con .ul .li span.logo {
	background-image: url(https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2021/eleimg/images/wap/waplogos.png);
}



.header .con .con_left .li span {}

.header .con .con_left .ul .li .drops {
	position: absolute;

	width: 100%;

	z-index: 999;

	display: none;

	left: 0px;

	overflow: hidden;

	/* border-top: 1px solid #666; */
}

.header .con .con_left .ul .li .drops .shop {
	width: 1300px;

	margin: 0 auto;

	background-color: #fff;

	padding: 27px 110px;

	/* display: flex;

	justify-content: space-between;

	flex-wrap: wrap; */

	overflow: hidden;

	padding-bottom: 40px;
}

.header .con .con_left .ul .li .drops .shop .list {
	float: left;

	margin-left: 31px;
}

.header .con .con_left .ul .li .drops .shop .list:nth-child(1) {
	margin-left: 0px;
}

.header .con .con_left .ul .li .drops .shop .list:nth-child(4) {
	margin-left: 0px;
}

.header .con .con_left .ul .li .drops .shop .list a {
	display: block;

	color: #000;

	text-align: center;

	width: 337px;

	height: 200px;

	margin-top: 25px;

	text-decoration: none;
}

.header .con .con_left .ul .li .drops .shop .list .list_img {
	overflow: hidden;
}

.header .con .con_left .ul .li .drops .shop .list .list_img img {
	display: block;
}

.header .con .con_left .ul .li .drops .shop .list .list_txt {
	display: block;

	margin-top: -10px;

	font-size: 18px;
}

/* .header .con .con_left .ul .li .drops img{

	width: 60% !important;

	display: block;

	margin: 0 auto;

	margin-top: 30px;

} */

.header .con .con_left .li .drops .drops_left {
	float: left;

	width: 50%;

	height: 85vh;

	background-color: #007aff;
}

.header .con .con_left .li .drops .drops_left .tab-content {
	height: 100%;
}

.header .con .con_left .li .drops .drops_left .tab-pane {
	height: 100%;
}

.header .con .con_left .li .drops .drops_left .tab-pane img {
	object-fit: cover;

	height: 100%;
}

.header .con .con_left .li .drops .drops_right {
	float: left;

	width: 50%;

	height: 85vh;

	background-color: #fff;

	display: flex;

	align-items: center;

	justify-content: center;
}

.header .con .con_left .li .drops .drops_right .nav-tabs>li {
	float: unset;

	margin-bottom: 0px;

	height: 100px;

	cursor: pointer;

	background-color: #fff;

	position: relative;

	line-height: 100px;
}

.header .con .con_left .li .drops .drops_right .nav-tabs {
	border: unset;
}

.header .con .con_left .li .drops .drops_right .nav-tabs>li>a {
	color: #000;

	font-size: 46px;

	height: 87px;

	border: 1px solid #fff;

	margin: 0;

	display: inline-block;

	padding: 0;

	margin: 0;
}

.header .con .con_left .li .drops .drops_right .nav-tabs>li.active>a {
	color: #00a0e9;

	cursor: pointer;

	background-color: unset;

	/*border: unset;

    border-bottom-color: unset; */
}

.header .con .con_left .li .drops .drops_right .nav-tabs>li.active>a::after {
	position: absolute;

	content: "";

	width: 100%;

	height: 2px;

	border-top: 2px solid #00a0e9;

	left: 0;

	bottom: 24px;
}

.header .con .con_left .li .drops .drops_right .nav-tabs>li>a:hover {
	background-color: unset;
}

.nav>li>a:focus,
.nav>li>a:hover {
	background-color: #fff;
}

.header .con .con_right {
	
}

.header .con .con_right a {
	color: #fff;

	font-size: 18px;

	text-decoration: none;
}


.header .con .con_right .menu_log {
	display: inline-block;

	background-position: center !important;

	background-repeat: no-repeat !important;

	background-size: 100% !important;

	width: 25px;

	height: 25px;

	line-height: 80px;

	vertical-align: bottom;

	margin-top: 35px;
}

.header.all_action .con .con_right .menu_log {
	background-image: url(https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629d55ef74c9bd0011bdaf63/16582806713690270267.png) !important;
}

</style>
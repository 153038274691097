<template>
  <div>
    <Headers class="group_49" />

    <div class="flex-row page s7">

      <div class="flex-col group">
        <div class="flex-col items-center section_1">
          <img data-aos="fade-up"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629d55ef74c9bd0011bdaf63/16582806683107329918.png"
            class="image_26" />
          <div class="flex-col items-center image-wrapper_3">
            <img data-aos="fade-up"
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629d55ef74c9bd0011bdaf63/16582806683108510534.png"
              class="image_2 page3s" />
          </div>
        </div>
        <div class="flex-col items-center section_3">
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629d55ef74c9bd0011bdaf63/16582806683105333806.png"
            class="image_31" />
          <div class="flex-col group_56">
            <img data-aos="fade-up"
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629d55ef74c9bd0011bdaf63/16582806683118507430.png"
              class="image_3" />
            <div class="flex-col group_4">
              <div class="flex-col items-center group_5">
                <img
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629d55ef74c9bd0011bdaf63/16582806683110052310.png"
                  class="image_4" />
                <span class="text_7">极致的驾驭体验</span>
              </div>
              <div class="flex-row group_6">
                <div @click="goAnchor('1F')" class="flex-col items-center section_19">
                  <div class="group_7">
                    <span class="text_8">强大的</span>
                    <span class="text_10">动力输出</span>
                  </div>
                </div>
                <div @click="goAnchor('2F')" class="flex-col items-center section_19 view_2">
                  <div class="group_7">
                    <span class="text_8">极致的</span>
                    <span class="text_10">轻量化设计</span>
                  </div>
                </div>
                <div @click="goAnchor('3F')" class="flex-col items-center section_19 view_4">
                  <div class="group_7">
                    <span class="text_8">完美的</span>
                    <span class="text_10">热管理系统</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-col group_8">
          <div @click="openvideo" data-aos="fade-up" class="flex-col items-center group_56" >

            <video ref="myVideo"
              poster="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629d55ef74c9bd0011bdaf63/16582806697835381168.png"
              src="@/assets/video/s7.mp4" loop="" oncontextmenu="return false" class="image_28"></video>
            <img :class="{ 'fade-out': videoState }"
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629d55ef74c9bd0011bdaf63/16582806698397322769.png"
              class="image_5" />
          </div>
          <div data-aos="fade-up" v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }"
            class="flex-col waypoint group_9 newgrid">
            <div v-if="countshow" class="grid group">
              <div class="flex-col items-center grid-item">
                <span class="text_9">0-50km/h加速</span>
                <div class="group_9">



                  <countTo class="text_10" :startVal='0' :endVal='3.7' :decimals="1" :duration='5000'></countTo>
                  <span class="text_11">S</span>
                </div>
              </div>
              <div class="flex-col items-center grid-item_1">
                <span class="text_12">最高时速</span>
                <div class="group_9">

                  <countTo class="text_13" :startVal='0' :endVal='92' :decimals="0" :duration='5000'></countTo>

                  <span class="text_14">KM/H</span>
                </div>
              </div>
              <div class="flex-col items-center grid-item_2">
                <span class="text_15">续航力</span>
                <div class="group_9">


                  <countTo class="text_17" :startVal='0' :endVal='155' :decimals="0" :duration='5000'></countTo>

                  <span class="text_18">KM</span>
                </div>
              </div>
              <div class="flex-col items-center grid-item">
                <span class="text_19">最大功率</span>
                <div class="group_9">

                  <countTo class="text_17" :startVal='0' :endVal='7.2' :decimals="1" :duration='5000'></countTo>

                  <span class="text_22">KW</span>
                </div>
              </div>
            </div>
            <!-- <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629d55ef74c9bd0011bdaf63/16582806698853726097.png"
            class="image_6" /> -->
          </div>
          <div id="1F" data-aos="fade-up" class="flex-col items-center group_13">
            <span class="text_21">强大的动力输出</span>
            <span class="text_22">多种技术，一种艺术</span>
          </div>
          <div data-aos="fade-up" class="flex-col group_14">
            <div class="justify-between group_15">
              <div class="flex-col"><span class="text_23">115V高电压平台</span></div>
              <div class="flex-col"><span class="text_24">VCU核心动力平台</span></div>
              <div class="flex-col"><span class="text_25">赛跑级空冷电机</span></div>
            </div>
            <div class="flex-row group_16">
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629d55ef74c9bd0011bdaf63/16582806699796978524.png"
                class="image_7" />
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629d55ef74c9bd0011bdaf63/16582806703186719606.png"
                class="image_7 image_29" />
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629d55ef74c9bd0011bdaf63/16582806704760192762.png"
                class="image_10" />
            </div>
            <div class="justify-between group_17">
              <div class="flex-col"><span class="text_26">用高电压平台，完成超越的第一步</span></div>
              <div class="flex-col items-center text-wrapper_4"><span class="text_27">智能于心，更胜于形！</span></div>
              <div class="flex-col"><span class="text_28">同级电摩中重量最轻，扭力最大</span></div>
            </div>
            <div class="justify-center group_56 view_6">
              <div class="group_19">
                <span class="text_29">采用115v高电压串联式电力结构<br></span>
                <span class="text_30">具有高电压，低电流，高效率打破行业极限</span>
              </div>
              <div class="group_20">
                <span class="text_31">具备强大的算法能力<br></span>
                <span class="text_32">在动态中实时平衡各项动力输出</span>
              </div>
              <div class="group_21">
                <span class="text_33">扭力两倍于125cc燃油车<br></span>
                <span class="text_34">效能最大逼近93.6%，传动效率达97%</span>
              </div>
            </div>
          </div>
          <div id="2F" data-aos="fade-up" class="flex-col items-center group_22">
            <span class="text_35">极致的轻量化设计</span>
            <span class="text_36">赛道级操控来自极致的设计追求</span>
          </div>
        </div>



        <div data-aos="fade-up" class="swiper swiper1">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="flex-col group_23">
                <img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2022/images/01.gif" alt="">
                <div class="group_24">
                  <span class="text_37">在保证强大动力输出的同时，<br>我们将整体核心构件做到了极致的轻量化设计整车重量仅为 <span class="text_40">
                      119公斤</span>，<br>其中包含2颗各11公斤的动力锂电池</span>

                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="flex-col group_23">
                <img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2022/images/02.png" alt="">
                <div class="group_24" style="top:88.1rem;">



                  <span class="text_37">对比同级燃油摩托车190公斤的总重，<br>S7的车身重量减少了 <span class="text_40">
                      37%</span>，<br>达到了这个量级操控重量上的最佳标准</span>

                </div>
              </div>
            </div>

          </div>
          <div class="swiper-pagination"></div>
        </div>

      </div>

      <div class="flex-col group_26">



        <div data-aos="fade-up" class="swiper swiper2">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="flex-col items-center section_7">
                <img
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629d55ef74c9bd0011bdaf63/16582806706232147757.png"
                  class="image_16" />
                <div class="flex-col group_56">
                  <div id="3F" class="flex-col items-center group_31">
                    <span class="text_43">完美的热管理系统</span>
                    <span class="text_44">巧妙的结构设计</span>
                  </div>
                  <div class="flex-col items-center group_28">
                    <span class="text_45">螺纹式的散热鳍片</span>
                    <span class="text_46">螺纹式的散热鳍片加之导风流道设计，可以使空气更大量的通过马达表面</span>
                  </div>

                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="flex-col items-center section_7">
                <img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2022/images/3.png" class="image_16" />
                <div class="flex-col group_56">
                  <div class="flex-col items-center group_31">
                    <span class="text_43">完美的热管理系统</span>
                    <span class="text_44">巧妙的结构设计</span>
                  </div>
                  <div class="flex-col items-center group_28">
                    <span class="text_45">赛道级空气导流设计 合理的结构布局</span>
                    <span class="text_46">将轻量化设计完美融入热管理系统的开发当中</span>
                  </div>

                </div>
              </div>
            </div>

          </div>
          <div class="swiper-pagination"></div>
        </div>
        <div data-aos="fade-up" class="flex-col group_29">
          <span class="text_47">赛道级专属设计</span>
          <div class="flex-col group_30">
            <div class="flex-row">
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629d55ef74c9bd0011bdaf63/16582806707567920419.png"
                class="image_13" />
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629d55ef74c9bd0011bdaf63/16582806707758431088.png"
                class="image_15" />
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629d55ef74c9bd0011bdaf63/16582806708132700284.png"
                class="image_13 image_30" />
            </div>
            <div class="flex-row group_32">
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629d55ef74c9bd0011bdaf63/16582806708718136461.png"
                class="image_17" />
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629d55ef74c9bd0011bdaf63/16582806708745256353.png"
                class="image_18" />
            </div>
          </div>
          <span class="text_48">多场景补能方案</span>
        </div>
        <div data-aos="fade-up" class="flex-row group_33">
          <div class=" swiper swiper3">
            <div class="swiper-wrapper">
              <div class="swiper-slide"><img src="@/assets/images/s1.png" class="image_20" /></div>
              <div class="swiper-slide"><img src="@/assets/images/s2.png" class="image_20" /></div>
              <div class="swiper-slide"><img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2022/images/s3.png" class="image_20" /></div>
            </div>
          </div>
        </div>
      </div>
      <div data-aos="fade-up" class="flex-col group_34">
        <div class="flex-col items-center section_8">
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629d55ef74c9bd0011bdaf63/16582806713103010019.png"
            class="image_27" />
          <div class="flex-col items-center group_56">
            <div class="flex-col items-center">
              <span class="text_49">直降11000元</span>
              <span class="text_50">24999</span>
              <span class="text_51">租电售价</span>
            </div>
            <div class="group_36">
              <span class="text_52">前一百名选择租电方案，199元/月</span>
              <span class="text_53">首年全免</span>
            </div>
          </div>
        </div>
      </div>
      <Footers class="group_54" />
    </div>
  </div>
</template>

<script>
import Headers from '../../components/headers/headers.vue';
import Footers from '../../components/footers/footers.vue';
import videos from "@/components/video.vue"
import AOS from "aos";

import countTo from 'vue-count-to';
export default {
  components: { Headers, Footers, countTo },
  data() {
    return {
      videoState: false,
      delay: 1000,
      countshow:false,
      intersectionOptions: {
        root: null,
        rootMargin: '0px 0px 0px 0px',
        threshold: [0, 1]
      },
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: ''
      }
    };
  },
  async mounted() {
    AOS.init({
      offset: 800,
      duration: 800,
      delay: 800,
      once: true,
    })
    await this.$nextTick(() => {
      new Swiper('.swiper1', {
        autoplay: true,
        effect: "fade",
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      }
      )
      new Swiper('.swiper2', {
        autoplay: true,
        effect: "fade",
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      }

      )
      new Swiper('.swiper3', {
        autoplay: true,
        spaceBetween: 0,
        slidesPerView: "auto",
        loop: true,
        centeredSlides: true,

      }

      )
    });
  },
  methods: {

    onWaypoint({ el, going, direction, _entry }) {
      // going: in, out
      // direction: top, right, bottom, left
      console.log(going)
      if (going === this.$waypointMap.GOING_IN) {
        console.log("waypoint going in!"); //激活-出现了
      }
      if (going === this.$waypointMap.GOING_OUT) {
        console.log("waypoint going out!");
      }

      if (direction === this.$waypointMap.DIRECTION_TOP) {
        console.log("waypoint going top!");
        this.countshow=true;

      }
      if (direction === this.$waypointMap.DIRECTION_BOTTOM) {
        console.log("waypoint going bottom!");
      }
    },
    openvideo() {
      this.$modal.open(videos)
    },
    goAnchor(selector) {
      this.$nextTick(() => {
        let anchorElement = document.getElementById(selector);

        if (anchorElement) {
          anchorElement.scrollIntoView();
        }
      })

    },
    // 操作视频播放、暂停
    operateVideo() {

      if (this.$refs.myVideo.paused) {
        this.$refs.myVideo.play();
        this.videoState = true;
      } else {
        this.$refs.myVideo.pause();
        this.videoState = false;
      }
    }

  },
};
</script>
<style lang="scss">


.swiper3 .swiper-slide img {
  opacity: 0.3;
}

.swiper3 .swiper-slide {
  background: #000;
}

.swiper3 .swiper-slide-active img {
  opacity: 1;
}

/* 自定义动画 */
@keyframes firstdiv {
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(-10%);
  }

  50% {
    transform: translateY(-20%);
  }

  75% {
    transform: translateY(-10%);
  }

  100% {
    transform: translateY(0);
  }

}

.page3s {
  animation: firstdiv 2s linear 2s infinite alternate;
}

.s7 .swiper {
  width: 100%;

}

.fade-out {
  opacity: 0;
}

.s7 .swiper-pagination-bullet {
  background-color: #ffffff4a;
  width: 15.1rem;
  height: 0.4rem;
  border-radius: 0;
}
</style>
<style scoped lang="scss">
.section_4 {
  margin-left: 36px;
  background-color: #ffffff4a;
  width: 151px;
  height: 4px;
}

.swiper3 .swiper-slide {
  width: 1200px !important;
}

.text_8 {
  color: #ffffff;
  font-size: 32px;

  line-height: 31px;
}

.text_10 {
  color: #00ffff;
  font-size: 32px;

  font-weight: 700;
  line-height: 30px;
}

.page {
  background-color: #000000;

  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  flex-wrap: wrap;
}

.view_11 {
  flex: 1 1 151px;
}

.image-wrapper_1 {
  padding: 26px 0 684px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629d55ef74c9bd0011bdaf63/16582806705017018456.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 1478px;
}

.image_16 {
  width: 97.0833vw;
  height: 66.1979vw;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  object-fit: cover;
}

.text_47 {
  align-self: center;
  color: #00ffff;
  font-size: 64px;
  font-weight: 700;
  line-height: 60px;
}

.text_48 {
  margin-top: 254px;
  align-self: center;
  color: #00ffff;
  font-size: 64px;

  font-weight: 700;
  line-height: 60px;
}

.text_21 {
  color: #00ffff;
  font-size: 64px;

  font-weight: 700;
  line-height: 60px;
}

.text_22 {
  margin-top: 28px;
  color: #ffffff;
  font-size: 30px;
  
  line-height: 29px;
}

.text_35 {
  color: #00ffff;
  font-size: 64px;

  font-weight: 700;
  line-height: 60px;
}

.text_36 {
  margin-top: 28px;
  color: #ffffff;
  font-size: 30px;
  
  line-height: 29px;
}

.text_37 {
  color: #ffffff;
  font-size: 24px;

  line-height: 36px;
}

.text_38 {
  color: #ffffff;
  font-size: 24px;

  line-height: 36px;
}

.text_39 {
  color: #ffffff;
  font-size: 24px;

  line-height: 36px;
}

.text_40 {
  color: #72fafc;
  font-size: 24px;

  line-height: 36px;
}

.text_41 {
  color: #ffffff;
  font-size: 24px;

  line-height: 36px;
}

.text_42 {
  color: #ffffff;
  font-size: 24px;

  line-height: 36px;
}

.group_31 {
  align-self: center;
}

.text_19 {
  color: #0079ff;
  font-size: 16px;

  font-weight: 700;
  line-height: 15px;
}

.text_20 {
  margin-right: 64px;
  color: #0079ff;
  font-size: 16px;

  font-weight: 700;
  line-height: 15px;
}

.text-wrapper_4 {
  width: 301px;
}

.text_43 {
  color: #00ffff;
  font-size: 64px;

  font-weight: 700;
  line-height: 61px;
}

.text_44 {
  margin-top: 27px;
  color: #ffffff;
  font-size: 30px;
  
  line-height: 28px;
}

.text_45 {
  color: #ffffff;
  font-size: 38px;

  font-weight: 700;
  line-height: 36px;
}

.text_46 {
  margin-top: 28px;
  color: #ffffff;
  font-size: 24px;


}

.text_49 {
  color: #ffffff;
  font-size: 32px;
  
  line-height: 31px;
}

.text_50 {
  margin-top: 35px;
  color: #00ffff;
  font-size: 108px;
  
  line-height: 76px;
}

.text_51 {
  margin-top: 33px;
  color: #ffffff;
  font-size: 27.5px;
  
  line-height: 26px;
}

.text_52 {
  color: #ffffff;
  font-size: 32px;
  
  line-height: 30px;
}

.text_53 {
  color: #ffffff;
  font-size: 32px;
  
  line-height: 31px;
}

.text_7 {
  color: #ffffff;
  font-size: 64px;

  font-weight: 700;
  line-height: 61px;
  position: absolute;
  left: 122px;
  top: 0;
}

.text_18 {
  margin-left: 199px;
  color: #0079ff;
  font-size: 16px;

  font-weight: 700;
  line-height: 15px;
}

.text_23 {
  margin-left: 18px;
  margin-right: 19px;
  color: #ffffff;
  font-size: 32px;

  font-weight: 700;
  line-height: 30px;
}

.text_24 {
  color: #ffffff;
  font-size: 34px;

  font-weight: 700;
  line-height: 32px;
}

.text_25 {
  margin-left: 22px;
  margin-right: 23px;
  color: #ffffff;
  font-size: 34px;

  font-weight: 700;
  line-height: 32px;
}

.text_26 {
  color: #ffffff;
  font-size: 20px;

  font-weight: 700;
  line-height: 20px;
}

.text_27 {
  color: #ffffff;
  font-size: 20px;

  font-weight: 700;
  line-height: 20px;
}

.text_28 {
  margin: 0 10px;
  color: #ffffff;
  font-size: 20px;

  font-weight: 700;
  line-height: 19px;
}

.text_29 {
  color: #b5b5b6;
  font-size: 16px;


}

.text_30 {
  color: #b5b5b6;
  font-size: 16px;


}

.text_31 {
  color: #b5b5b6;
  font-size: 16px;


}

.text_32 {
  color: #b5b5b6;
  font-size: 16px;


}

.text_33 {
  color: #b5b5b6;
  font-size: 16px;


}

.text_34 {
  color: #b5b5b6;
  font-size: 16px;


}

.text_16 {
  color: #0079ff;
  font-size: 16px;

  font-weight: 700;
  line-height: 15px;
}

.text_17 {
  margin-left: 124px;
  color: #0079ff;
  font-size: 16px;

  font-weight: 700;
  line-height: 15px;
}

.group_49 {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}

.group {
  width: 100%;

}

.view_7 {
  width: 338px;
  position: absolute;
  top: 5059px;
  left: 50%;
  transform: translateX(-50%);
  display: none;
}

.group_26 {
  width: 100%;
}

.group_34 {
  width: 100%;
}

.group_54 {
  width: 100%;
}

.section_1 {}

.section_20 {
  background-color: #ffffff;
  width: 151px;
  height: 4px;
}

.section_3 {
  padding: 232px 0 591px;
  height: 1407px;
  position: relative;
}

.group_8 {
  margin-top: -337px;
  align-self: center;
  width: 1200px;
  
  position: relative;
}
.group_56{
  height: 675px;
}

.group_23 {
  margin: 38px auto 0;
  padding-top: 58px;
  align-self: center;
  width: 1478px;
  position: relative;
  background-color: #000;
}

.section_7 {

  padding-top: 129px;
  position: relative;
  padding-bottom: 100px;
  background-color: #000;
}

.group_29 {
  margin-top: 254px;
  align-self: center;
}

.group_33 {
  margin-top: 63px;
}

.section_8 {
  padding: 107px 0 65px;
  height: 52.3438vw;
  position: relative;
  width: 100%;
  justify-content: center;
  flex-direction: row;
}

.image_26 {
  width: 100vw;

}

.image-wrapper_3 {
  width: 30px;
  position: relative;
  top: -150px;
}

.image_31 {
  width: 100vw;
  height: 73.2813vw;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}

.group_9 {
  margin-top: 67px;
}

.group_13 {
  margin-top: 226px;
  align-self: center;
}

.group_14 {
  margin-top: 67px;
}

.group_22 {
  margin-top: 189px;
  align-self: center;
}

.image_12 {
  width: 1200px;
  height: 794px;
  position: absolute;
  left: 115px;
  top: 0;
}

.group_24 {
  line-height: 36px;
  text-align: center;
  width: 481px;
  position: absolute;
  left: 480px;
  top: 781px;
}

.group_30 {
  margin-top: 67px;
}

.image_19 {
  flex-shrink: 0;
  width: 360px;

}

.image_20 {
  flex: 1 1 auto;
  width: 1200px;

}

.image_21 {
  flex-shrink: 0;
  width: 360px;

}

.image_27 {
  width: 100vw;
  height: 52.3438vw;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}

.image_2 {
  width: 30px;
  height: 100px;
}

.image_3 {
  align-self: center;
  width: 934px;
  height: 291px;
}

.group_4 {
  margin-top: 97px;
}

.image_28 {
  width: 1200px;
  height: 675px;
}

.image_5 {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 27px;
  bottom: 22px;
}

.group_10 {
  padding: 0 5px;
}

.image_6 {
  margin-top: 19px;
  width: 1199px;
  height: 34px;
}

.group_15 {
  padding-left: 48px;
  padding-right: 35px;
}

.group_16 {
  margin-top: 19px;
}

.group_17 {
  margin-top: 28px;
  padding-left: 34px;
  padding-right: 26px;
}

.view_6 {
  margin: 22px 0 31px;
}

.image_11 {
  width: 815px;
  height: 173px;
}

.group_28 {
  margin-top: 1100px;
}

.view_23 {
  flex: 1 1 151px;
}

.view_10 {
  margin-top: 65px;
  align-self: center;
  width: 338px;
}

.group_32 {
  margin-top: 16px;
}

.group_36 {
  // margin-top: 761px;
  // line-height: 31px;
}

.group_5 {
  padding-top: 26px;
  align-self: center;
  width: 703px;
  position: relative;
}

.group_6 {
  margin-top: 17px;
}

.group_6>div {
  cursor: pointer;
}

.image_7 {
  flex: 1 1 362px;
  width: 362px;
  height: 206px;
}

.image_29 {
  margin-left: 57px;
}

.image_10 {
  margin-left: 58px;
  flex: 1 1 362px;
  width: 361px;
  height: 206px;
}

.group_19 {

  text-align: center;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 355px;
}

.group_20 {

  text-align: center;
}

.group_21 {

  text-align: center;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 355px;
}

.image_13 {
  flex: 1 1 386px;
  width: 386px;
  height: 443px;
}

.image_15 {
  margin-left: 21px;
  flex: 1 1 386px;
  width: 386px;
  height: 444px;
  margin-top: 0;
}

.image_30 {
  margin-left: 20px;
}

.image_17 {
  flex: 1 1 591px;
  width: 591px;
  height: 338px;
}

.image_18 {
  margin-left: 19px;
  flex: 1 1 591px;
  width: 590px;
  height: 339px;
}

.image_4 {
  width: 703px;
  height: 71px;
}

.section_19 {
  padding: 24px 0 27px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629d55ef74c9bd0011bdaf63/16582806683117343504.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 341px;
  height: 82px;
}

.view_2 {
  margin-left: 89px;
}

.view_4 {
  margin-left: 88px;
}

.group_12 {
  flex-shrink: 0;
}

.group_56 {
  position: relative;
  justify-content: space-between;
  height: 100%;
}

.group_7 {
  line-height: 31px;
}

.newgrid {
  .grid-item_1 {
    padding: 23.5px 0;
  }

  .grid-item_2 {
    padding: 23.5px 0;
  }

  .grid-item {
    padding: 23.5px 0;
  }

  .grid {
    margin: 0;

    display: grid;
    grid-template-columns: repeat(4, 25%);
  }

  .group_9 {
    margin-top: 21px;

  }

  .text_9 {
    color: #0079ff;
    font-size: 24px;

    font-weight: 700;
    line-height: 22px;
  }

  .text_12 {
    margin-left: 20px;
    color: #0079ff;
    font-size: 24px;

    font-weight: 700;
    line-height: 23px;
  }

  .text_15 {
    align-self: center;
    color: #0079ff;
    font-size: 24px;

    font-weight: 700;
    line-height: 23px;
  }

  .text_19 {
    color: #0079ff;
    font-size: 24px;

    font-weight: 700;
    line-height: 23px;
  }

  .text_27 {
    color: #b5b5b6;
    font-size: 24px;

    line-height: 36px;
  }

  .text_28 {
    color: #b5b5b6;
    font-size: 24px;

    line-height: 36px;
  }

  .text_10 {
    color: #ffffff;
    font-size: 60px;
    font-family: Eurostile;
    line-height: 47px;
    margin-left: 0;
  }

  .text_11 {
    color: #ffffff;
    font-size: 24px;
    font-family: Eurostile;
    line-height: 19px;
    margin-left: 0;
  }

  .text_13 {
    color: #ffffff;
    font-size: 60px;
    font-family: Eurostile;
    line-height: 47px;
    margin-left: 0;
  }

  .text_14 {
    color: #ffffff;
    font-size: 24px;
    font-family: Eurostile;
    line-height: 20px;
    margin-left: 0;
  }

  .text_16 {
    color: #ffffff;
    font-size: 60px;
    font-family: Eurostile;
    line-height: 46px;
    letter-spacing: -12px;
    margin-left: 0;
  }

  .text_17 {
    color: #ffffff;
    font-size: 60px;
    font-family: Eurostile;
    line-height: 46px;
    margin-left: 0;
  }

  .text_18 {
    color: #ffffff;
    font-size: 24px;
    font-family: Eurostile;
    line-height: 19px;
    margin-left: 0;
  }

  .text_20 {
    color: #ffffff;
    font-size: 60px;
    font-family: Eurostile;
    line-height: 45px;
    letter-spacing: -6px;
    margin-right: 0;
  }

  .text_21 {
    color: #ffffff;
    font-size: 60px;
    font-family: Eurostile;
    line-height: 47px;
  }

  .text_22 {
    color: #ffffff;
    font-size: 24px;
    font-family: Eurostile;
    line-height: 19px;
  }
}
</style>
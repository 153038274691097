<template>
  <div class="flex-col">
    <div class="flex-col section_9">
      <div class="justify-between group_37">
        <div class="flex-row group_38">
          <div class="flex-col group_39">
            <div class="flex-col group_50">
              <div class="section_13"><!--*--></div>
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629d55ef74c9bd0011bdaf63/16582806683095054057.png"
                class="image_22"
              />
            </div>
            <span class="text_54">微信公众号</span>
          </div>
          <div class="flex-col group_40">
            <div class="flex-col group_50">
              <div class="section_13"><!--*--></div>
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629d55ef74c9bd0011bdaf63/16582806683109228276.png"
                class="image_23"
              />
            </div>
            <span class="text_56">官方抖音号</span>
          </div>
          <div class="flex-col group_39 view_18">
            <div class="flex-col group_50">
              <div class="section_13"><!--*--></div>
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629d55ef74c9bd0011bdaf63/16582806683107106835.png"
                class="image_24"
              />
            </div>
            <span class="text_54">官方APP</span>
          </div>
        </div>
        <div class="flex-col items-start group_41">
          <span class="text_58">营销电话： 0519-68197852</span>
          <span class="text_59">服务热线： 400-135-6887</span>
          <span class="text_60">企业邮箱： ev@mail.kymco.com.cn</span>
          <span class="text_61">公司地址： 江苏省常州市新北区吕汤路10号</span>
        </div>
      </div>
      <div class="flex-row group_42">
        <span class="text_62">Copyright © 2018 常州光阳摩托车有限公司 All rights reserved .</span>
        <span class="text_63">苏ICP备16055340号</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {};
    },
    methods: {},
  };
</script>

<style scoped lang="css">
  .section_9 {
    padding: 0 258px 65px;
    background-color: #ffffff;
  }
  .group_37 {
    padding: 78px 0 67px;
    border-bottom: solid 1px #b5b5b6;
  }
  .group_42 {
    padding: 19px 2px 0;
  }
  .group_38 {
    margin-top: 8px;
  }
  .group_41 {
    margin-right: 9px;
  }
  .text_62 {
    color: #6f6f6f;
    font-size: 14px;
    font-family: OPPOSans;
    line-height: 17px;
  }
  .text_63 {
    margin-left: 3px;
    color: #6f6f6f;
    font-size: 14px;
    font-family: OPPOSans;
    line-height: 17px;
  }
  .group_39 {
    width: 116px;
  }
  .group_40 {
    margin-left: 62px;
    width: 117px;
  }
  .view_18 {
    margin-left: 61px;
  }
  .text_58 {
    color: #000;
    font-size: 16px;
    font-family: OPPOSans;
    line-height: 22.5px;
  }
  .text_59 {
    margin-top: 17.5px;
    color: #000;
    font-size: 16px;
    font-family: OPPOSans;
    line-height: 22.5px;
  }
  .text_60 {
    margin-top: 17.5px;
    color: #000;
    font-size: 16px;
    font-family: OPPOSans;
    line-height: 22.5px;
  }
  .text_61 {
    margin-top: 17.5px;
    color: #000;
    font-size: 16px;
    font-family: OPPOSans;
    line-height: 22.5px;
  }
  .group_50 {
    position: relative;
  }
  .text_54 {
    margin-top: 16px;
    align-self: center;
    color: #000;
    font-size: 14px;
    font-family: OPPOSans;
    line-height: 14px;
  }
  .text_56 {
    margin-top: 16px;
    align-self: center;
    color: #000;
    font-size: 14px;
    font-family: OPPOSans;
    line-height: 14px;
  }
  .section_13 {
    opacity: 0.3;
    border-radius: 20px;
    height: 116px;
    border: solid 1.22px #12050a;
  }
  .image_22 {
    width: 97px;
    height: 96px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .image_23 {
    width: 101px;
    height: 101px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .image_24 {
    width: 95px;
    height: 95px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
</style>